import React, { createContext, ReactNode, useCallback, useContext, useState } from "react"

type DrawerContextProperties = {
    isDrawerOpen: boolean
    toggleDrawer: () => void
}

type DrawerContextProviderProperties = {
    children?: ReactNode
}

const DrawerContext = createContext<DrawerContextProperties>(undefined)

export const DrawerContextProvider = (props: DrawerContextProviderProperties) => {
    const DRAWER_OPEN_KEY = "drawerOpen"

    const getIsDrawerOpenFromStorage = useCallback((): string | null => {
        return localStorage.getItem(DRAWER_OPEN_KEY)
    }, [])

    /**
     * Check if the drawer is open in local storage. If it is not set, default is false (closed).
     */
    const getIsOpen = useCallback(() => {
        const isDrawerOpen = getIsDrawerOpenFromStorage()

        return isDrawerOpen === "true"
    }, [getIsDrawerOpenFromStorage])

    /**
     * Set the drawer open state in local storage
     * @param open
     */
    const setIsOpenInStorage = (open: boolean) => {
        localStorage.setItem(DRAWER_OPEN_KEY, open.toString())
    }

    const [isOpenState, setIsOpenState] = useState(getIsOpen())

    /**
     * Toggle the drawer open state
     */
    const toggleIsOpen = () => {
        const isOpen = getIsOpen()
        setIsOpenState(!isOpen)
        setIsOpenInStorage(!isOpen)
    }

    return (
        <DrawerContext.Provider
            value={{
                isDrawerOpen: isOpenState,
                toggleDrawer: toggleIsOpen,
            }}
        >
            {props.children}
        </DrawerContext.Provider>
    )
}

export const useDrawerContext = () => {
    return useContext(DrawerContext)
}
